*{
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: black;
}

/*Main*/
.arrow{
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  color: #444;
}

#instagram-icon{float: right;}

.main-wrapper{
  height: 100vh;
  background-size:cover;
  background: black;
  padding-top: 20%;
  color: grey;
}

.about-me-header {
  font-size: 36px;
  font-weight: 450;
  text-align: center;
  color: #999; /* Light grey color for the header */
}

/* Subsequent paragraphs */
.about-me-info {
  font-size: 23px;
  font-weight: 400;
  text-align: center;
  color: #777; /* Slightly darker grey color for the first paragraph */
}

.about-me-doing {
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #555; /* Darker grey color for the second paragraph */
}

.skill-text {
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  color: #444; /* Even darker grey color for the third paragraph */
}

.about-me-doing, .about-me-header, .about-me-info:hover{
cursor: pointer;
}

.about-header{
  font-size: 33px;
  font-weight: 450;
  margin: 10px;
  color: #666;
}

.about-paragraph{
  color: #666;
  margin-inline: 20px;
  font-size: 25px;
}


.main-icons {
  position: absolute;
  bottom: 20px;
  right: 20px; /* Adjust as needed */
  cursor: pointer;
}

/* Darken icons on hover */
.main-icons img:hover {
  filter: brightness(0.7); /* Darken the image on hover */
  transition: 0.2s ease-in;
}


/*Projekty*/
.project-wrapper {
  display: flex;
  align-items: center;
  margin-left: 2%;
  margin-right: 2%;
}


.projects-header{
  padding-top: 5%;
  color: #666;
  padding-bottom: 1%;
  font-size: 28px;
  font-weight: 450;
  padding-left: 1%;
}

.project-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#project-img {
  width: 160px;
  height: auto;
  padding:1%;
  margin-left: 2%;
}

.project-paragraph {
  color: #555;
  margin-left: 20px; /* Adjust margin as per your need */
  font-size: 20px;
}

.project-wrapper:hover .project-content {
  transition: 0.3s ease-in-out;
  transform: scale(1.017);
}

/*Responsive*/
@media screen and (max-width: 600px){

  .main-wrapper{height: 90vh;}

  .about-me-header{
    margin-left: 5%;
    padding-top: 20%;
    font-size: 21px;
  }
  .about-me-info{
    font-size: 13px;
    margin-left: 5px;
  }

  .about-header, .projects-header, .contact-header{
    margin: 5px;
    font-size: 16px;
  }

  .about-header, .projects-header{margin-left: 4%;}

  .about-paragraph, .project-paragraph{
    font-size: 15px;
    padding-inline: 22px;
  }

  .send, .message, .email-input, .name-input{font-size: 18px;}

  .copyright{font-size: 10px;}

  #project-img{ width: 100px;}

  .project-wrapper{ margin-left: 3%;}
}

@media only screen and (max-width: 1024px){

  .main-wrapper{
    margin-left: 6.5%;
    padding-top: 30%;
    height: 100vh;
  }
}

/*kontakt*/
.contact-section{
  padding-top: 5%;
  height: 73vh;
}

.contact-header{
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: 300;
  font-size: 30px;
  color: darkgrey;
}

.name-input, .email-input{
  width: 200px;
  height: 40px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 20px;
  border: white;
  font-size: large;
  padding: 10px;
}

.message{
  width: 250px;
  height: 100px;
  background-color: white;
  color: black;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 15px;
  font-size: large;
  padding: 10px;
}

.send{
  cursor: pointer;
  font-size: 20px;
  background-color: transparent;
  border: 1px;
  color: white;
  border-radius: 4px;
  box-shadow: inset 0 0 0 0 #f046c8;
  transition: ease-out 0.7s;
  outline: none;
  margin: 20px;
  border-radius: 20px;
}

.send:hover{
  box-shadow: inset 300px 0 0 0 rgb(162, 0, 156);
  color: white ;
}

form{
  font-size: 20px;
  display: grid;
  justify-content: center;
  color: lightgrey;
}

/*Footer*/
.footer{
  height: 10px;
  width: 100%;
  display:inline-block;
  margin-top: 5%;
  color: #666;
}

.copyright{
  text-align: center;
  position: sticky;
}


.email-info{
  font-weight: 500;
  color: rgb(0, 192, 0);
  text-align: center;
  -bottom: 10px;
}






